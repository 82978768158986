<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop @formReset="formReset()" />
    <NavDrawer />
    <v-row class="mt-5"><!-- Phantom row --></v-row>
    <v-main>
      <v-row class="mt-2">
        <v-col lg="3" md="1" cols="1">
          <!-- Fills extra space in the row -->
        </v-col>
        <v-col cols="12" lg="9" offset-lg="3" class="mt-12 text-left">
          <p v-if="loaded" class="ml-10 ml-lg-1 mt-12">
            <span>
              <router-link :to="{ path: '/'}"
                           class="mr-1">Home</router-link>
              <v-icon small color="black">mdi-chevron-right</v-icon>
              <router-link :to="{ path: '/family/' + floraData[0].family_name, params: { family_name: floraData[0].family_name }}"
                           class="mr-1">
                {{ floraData[0].family_name }}
              </router-link>
              <v-icon small color="black">mdi-chevron-right</v-icon>
<!--              <router-link :to="{ path: '/genus/' + floraData[0].genus, params: { genus: floraData[0].genus }}">{{ floraData[0].genus }}</router-link>-->
              <router-link :to="{ path: '/genus/' +
                capitalizeFirstLetter(floraData[0].scientific_name.split(' ')[0]),
                params: { genus: floraData[0].scientific_name.split(' ')[0] }}">
                  {{ capitalizeFirstLetter(floraData[0].scientific_name.split(' ')[0]) }}
              </router-link>

            </span>
          </p>
          <p v-if="loaded" class="font-weight-medium ml-10 ml-lg-1" :class="{'display-1': $vuetify.breakpoint.lgAndUp, 'headline': $vuetify.breakpoint.mdAndDown}">
            <em v-if="multipleLoaded">{{ capitalizeFirstLetter(floraData[0].scientific_name.split(' ')[0] + ' ' + floraData[0].scientific_name.split(' ')[1]) }}  </em>
            <em v-else>{{ capitalizeFirstLetter(floraData[0].scientific_name) }}  </em>
            <span :class="{'body-2': $vuetify.breakpoint.lgAndUp, 'caption': $vuetify.breakpoint.mdAndDown}">{{  floraData[0].author }}</span>
            <v-btn v-if="admin" small dark color="#036358" class="float-right mr-6 elevation-6" @click.native="imageManager">Edit Images</v-btn>
            <v-divider></v-divider>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <FloraDetails  :floraData="floraData"
                      :imageData="imageData"
                      :imagesFilter="imagesFilter"
                      :mapBaseURL="mapBaseURL"
                      :locationData="locationData"
                      :citationUrl="citationURL"
                      :admin="admin"
                      :priorityImageSrc="priorityImageSrc"
                      :priorityImageSrcHover="priorityImageSrcHover"
                      :priorityImageCaption="priorityImageCaption"
                      :priorityImagePhotographer="priorityImagePhotographer"
                      v-show="loaded">
          <component :loaded="loaded" :key="text_key" :template="textData" :is="dynamic_text"></component>
        </FloraDetails>
      </v-row>
      <v-row>
        <v-col cols="10" lg="8" sm="10" offset-lg="3" offet-md="2" offset="1" class="mt-4 text-justify">
          <p v-if="loaded" class="body-2"><span class="font-weight-bold">Citation:</span><br>
            <span class="font-italic">MICHIGAN FLORA ONLINE.</span> A. A. Reznicek, E. G. Voss, & B. S. Walters. February 2011. University of Michigan. Web. {{ getDate }} <br>
            {{ baseURL }}#{{ citationUrl }}
          </p>
        </v-col>
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import FloraDetails from '@/views/templates/FloraRecord/FloraDetails.vue';
import DynamicText from '@/components/dynamicText';


export default {
  name: 'FloraRecord',
  components: {
    NavTop,
    NavDrawer,
    TheFooter,
    FloraDetails,
    DynamicText,
  },
  data() {
    return {
      crumbs: [],
      date: new Date(),
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
      dynamic_text: 'DynamicText',
      text_key: 0,
      isMobile: false,
      baseURL: process.env.VUE_APP_PUBLIC_PATH,
    };
  },
  beforeCreate() {
    this.$store.dispatch('floraRecord/getRecordData');
  },
  created() {
    this.citationUrl = this.$route.path;
  },
  methods: {
    imageManager() {
      const { id } = this.$router.currentRoute.params;
      const path = `_pid_${id}`;
      this.$router.push({ name: 'Image Manager', query: { path } }).catch(() => {});
      this.$router.go();
    },
    onResize() {
      this.isMobile = window.innerWidth < 1264;
    },
    formReset() {
      // This is the only page where clearing the search form by clicking on another nav button
      // might not be taken care of the router beforeEach, so if we're navigating away from here
      // because a nav button was clicked (as opposed to the browser back button), reset
      // openSearch in the store.
      // No longer needed after splitting search & results into separate non-nested components.
      // console.log('flora record formReset');
      // this.searchLoading();
    },
    ...mapActions('floraSearch', {
      searchLoading: 'searchLoading',
    }),
  },
  computed: {
    multipleLoaded() {
      return this.loaded && this.floraData.length > 1;
    },
    ...mapState('currentUser', {
      authenticated: state => state.authenticated,
      email: state => state.email,
      admin: state => state.admin,
    }),
    ...mapState('floraRecord', {
      floraData: state => state.floraData,
      textData: state => state.textData,
      imageData: state => state.imageData,
      mapBaseURL: state => state.mapBaseURL,
      locationData: state => state.locationData,
      priorityImageSrc: state => state.priorityImageSrc,
      priorityImageSrcHover: state => state.priorityImageSrcHover,
      priorityImageCaption: state => state.priorityImageCaption,
      priorityImagePhotographer: state => state.priorityImagePhotographer,
      citationURL: state => state.citationURL,
      loaded: state => state.loaded,
    }),
    getDate() {
      return `${this.monthNames[this.date.getMonth()]}, ${this.date.getDate()}, ${this.date.getFullYear()}`;
    },
    imagesFilter() {
      // returns array of existing images.
      return this.imageData.filter(image => image.exists);
    },
  },
  watch: {
    $route: {
      // with immediate set to true, handler gets called on first mount as well
      immediate: true,
      // handler will be called every time the route changes.
      // keeps component data in sync
      // reset component state and fetch new data
      async handler() {
        this.text_key += 1; // changing the key value assigned resets the component
        await this.$store.dispatch('floraRecord/getRecordData');
      },
    },
  },
};

</script>

<style scoped>

  .map-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    display: inline-block;
  }


  .lightbox {
    /** Default lightbox to hidden */
    display: none;

    /** Position and style */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
  }

  .lightbox img {
    /** Pad the lightbox image */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .lightbox:target {
    /** Remove default browser outline */
    outline: none;

    /** Show lightbox **/
    display: block;
  }
  .v-application p {
    margin-bottom: 12px !important;
  }
  .v-data-footer ::v-deep {
    justify-content: flex-start !important;

  }
  [v-cloak] {
    display: none;
  }
  .edit-link {
    text-align: right;
    transition: .1s;
  }
  .edit-link:hover {
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }

</style>
