<template>
  <div id="lightgallery">
  </div>
</template>

<script>
import 'lightgallery.js';
import 'lightgallery.js/dist/css/lightgallery.css';
import 'lg-thumbnail.js/dist/lg-thumbnail';

export default {
  name: 'LightGallery',
  props: {
    galleryItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openGallery() {
      const dynamicElements = [];
      this.galleryItems.forEach((value) => {
        dynamicElements.push({
          src: value.src,
          thumb: value.thumb,
          // eslint-disable-next-line
          subHtml: value.subHtml + '<h3 id="scientific-name">' + this.capitalizeFirstLetter(value.scientificName) + '</h3>',
          caption: value.alt,
        });
      });

      const lg = document.getElementById('lightgallery');
      window.lightGallery(lg, {
        mode: 'lg-slide',
        download: false,
        thumbnail: true,
        dynamic: true,
        preload: 2,
        hideBarsDelay: 1500000,
        dynamicEl: dynamicElements,
      });

      // eslint-disable-next-line no-unused-vars
      lg.addEventListener('onCloseAfter', (event, index, fromTouch, fromThumb) => {
        this.$emit('close-gallery');
        window.lgData[lg.getAttribute('lg-uid')].destroy();
      }, false);

      window.lightGallery(lg);
    },
  },
  mounted() {
    this.openGallery();
    this.getElementById('scientificName').style.fontStyle = 'italic';
  },
};
</script>

<style>
  #scientific-name{
    font-style: italic;
  }
  #image-caption{
    font-style:  normal;
    padding-bottom: 10px;
    font-size: 16px;
  }
  .lg-outer .lg-image{
    max-height: calc(100% - 130px);
  }
  .lg-toolbar{
    height: 60px;
  }
  .lg-sub-html{
    height: 65px;
    padding: 6px 40px;
  }
  #image-caption{
    margin:0;
    padding:0;
  }
</style>
