<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop @formReset="formReset()" />
    <AlertMessage />
    <v-row class="mt-12">
      <!-- Space filler -->
    </v-row>
    <v-row class="mt-3">
      <!-- Space filler -->
    </v-row>
    <v-main>
      <v-row no-gutters align="center">
        <v-col lg="3" md="2" cols="1">
          <NavDrawer />
        </v-col>
        <v-col cols="10" lg="6" offset-lg="6" md="8" offset-md="5" offset-sm="4">
          <v-form v-on:keyup.enter.native="sendFormData"
                  class="form mb-5"
                  ref="form"
                  v-if="!isSubmitted">
            <div class="headline font-weight-regular mb-3">Search</div>
            <v-text-field v-model="formData.common_name"
                          label="Common Name"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.scientific_name"
                          label="Scientific Name"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.genus"
                          class="mb-n4 pb-n4"
                          label="Genus"
            ></v-text-field>
            <v-checkbox v-model="formData.exact_match_genus"
                        label="Genus Text Matches Exactly"
                        true-value="yes"
                        false-value="no"
                        class="mt-n4 pt-n4"
                        clearable
            ></v-checkbox>
            <v-text-field v-model="formData.family"
                          label="Family"
                          dense
            ></v-text-field>
            <v-checkbox v-model="formData.exact_match_family"
                        label="Family Text Matches Exactly"
                        true-value="yes"
                        false-value="no"
                        class="mt-n4 pt-n4"
                        clearable
            ></v-checkbox>
            <v-select v-model="formData.na"
                      :items="selectOptions.naSelect"
                      label="Native/Adventive"
                      multiple
                      dense
                      clearable></v-select>
            <v-select v-model="formData.c"
                      :items="selectOptions.cSelect"
                      label="Coefficient of Conservatism"
                      multiple
                      dense
                      clearable></v-select>
            <v-select v-model="formData.w_wet"
                      :items="selectOptions.wetSelect"
                      label="W/Wetness"
                      multiple
                      dense
                      clearable></v-select>
            <v-select v-model="formData.phys"
                      :items="selectOptions.physSelect"
                      label="Physiognomy"
                      multiple
                      dense
                      clearable></v-select>
            <v-select v-model="formData.status"
                      :items="selectOptions.statusSelect"
                      label="State Status"
                      multiple
                      dense
                      clearable></v-select>
            <v-select v-model="formData.county"
                      :items="selectOptions.countySelect"
                      label="County"
                      multiple
                      dense
                      clearable></v-select>
            <span>
              <v-btn v-on:click="sendFormData"
                     :loading="loading"
                     :disabled="loading"
                     color="primary"
                     class="mt-4 ma-1"
                     @click="loader = 'loading'">Search</v-btn>
              <v-btn class="mt-4 ma-1"
                     @click="formClear()">Clear</v-btn>
              <v-dialog v-model="dialog" width="500" :fullscreen="$vuetify.breakpoint.xsOnly">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="mt-4 ma-1">
                    Search Tips
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2" primary-title>
                    Search Tips
                  </v-card-title>
                  <v-card-text class="mt-4 text-justify">
                    <ul>
                      <li class="mb-2 subtitle-1" style="color:black">Synonyms linking to the three volume Michigan Flora are included in the Scientific Name searches (but not the Genus searches), so entering the genus "Panicum" in the Scientific Name search field will also return species in the genus "Dichanthelium"</li>
                      <li class="mb-2 subtitle-1" style="color:black">A "%" can be used as a wildcard, so "Mu%gia" entered in the Genus field will return all the species in "Muhlenbergia"; "Cat%tail" entered in the Common Name filed will return all species of "cat-tails", which is useful when your're unsure if the name is hyphenated or two words.</li>
                      <li class="mb-2 subtitle-1" style="color:black">Some searches, such as Scientific Name, can be conducted on partial entries, so entering "bia cor" in the Scientific Name search field will return "Euphorbia corollata"</li>
                    </ul>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                    >
                      Return to search
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </span>
            <v-col class="mt-4" cols="12" v-if="noResults">
              <p class="title">No results found.</p>
            </v-col>
          </v-form>
          <v-overlay :value="loaderOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-col>
        <!--v-row class="mt-12">
          <v-col cols="11" lg="8" offset-lg="3" offset="1" class="mt-12" v-if="isSubmitted">
            <FloraResults @formReset="formReset()" :results="searchResponse"/>
          </v-col>
        </v-row-->
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';

export default {
  name: 'Search',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    TheFooter,
  },
  data() {
    return {
      searchResponse: [],
      noResults: false,
      isSubmitted: false,
      isError: false,
      submitting: false,
      formData: getInitialData(),
      selectOptions: {
        naSelect: ['N', 'A'],
        cSelect: ['*', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        wetSelect: ['5 UPL', '3 FACU', '0 FAC', '-3 FACW', '-5 OBL'],
        physSelect: ['Ad Fern', 'Ad A-Forb', 'Ad B-Forb', 'Ad P-Forb', 'Ad A-Grass', 'Ad P-Grass', 'Ad A-Sedge', 'Ad P-Sedge', 'Ad Shrub', 'Ad Tree', 'Ad A-Vine', 'Ad P-Vine',
          'Ad W-Vine', 'Nt Fern', 'Nt Fern Al', 'Nt A-Forb', 'Nt B-Forb', 'Nt P-Forb', 'Nt A-Grass', 'Nt P-Grass', 'Nt A-Sedge', 'Nt P-Sedge', 'Nt Shrub', 'Nt Tree', 'Nt A-Vine',
          'Nt B-Vine', 'Nt P-Vine', 'Nt W-Vine'],
        statusSelect: ['Extirpated', 'Endangered', 'Threatened', 'Special Concern'],
        // statusSelect: [{status: 'Extirpated', code:'status=X%'},
        //   {status: 'Endangered', code:'status=E&'},
        //   {status: 'Threatened', code:'status=T&'},
        //   {status: 'Special Concern', code:'status=SC&'}],
        countySelect: ['Alcona', 'Alger', 'Allegan', 'Alpena', 'Antrim', 'Arenac', 'Baraga', 'Barry',
          'Bay', 'Benzie', 'Berrien', 'Branch', 'Calhoun', 'Cass', 'Charlevoix', 'Cheboygan',
          'Chippewa', 'Clare', 'Clinton', 'Crawford', 'Delta', 'Dickinson', 'Eaton', 'Emmet',
          'Genesee', 'Gladwin', 'Gogebic', 'Grand Traverse', 'Gratiot', 'Hillsdale', 'Houghton', 'Huron',
          'Ingham', 'Ionia', 'Iosco', 'Iron', 'Isabella', 'Jackson', 'Kalamazoo', 'Kalkaska', 'Kent',
          'Keweenaw', 'Lake', 'Lapeer', 'Leelanau', 'Lenawee', 'Livingston',
          'Luce', 'Mackinac', 'Macomb', 'Manistee', 'Marquette', 'Mason', 'Mecosta',
          'Menominee', 'Midland', 'Missaukee', 'Monroe', 'Montcalm', 'Montmorency', 'Muskegon', 'Newaygo', 'Oakland',
          'Oceana', 'Ogemaw', 'Ontonagon', 'Osceola', 'Oscoda', 'Otsego', 'Ottawa', 'Presque Isle', 'Roscommon',
          'Saginaw', 'Sanilac', 'Schoolcraft', 'Shiawassee', 'St. Clair', 'St. Joseph', 'Tuscola', 'Van Buren',
          'Washtenaw', 'Wayne', 'Wexford'],
      },
      loader: null,
      loading: false,
      loaderOverlay: false,
      dialog: false,
    };
  },
  mounted() {
    this.$root.$on('resetSearch', this.formReset);
  },
  methods: {
    async sendFormData() {
      const requestURL = this.constructUrl(this.formData);
      await this.$store.dispatch('floraSearch/getFloraResults', requestURL);
      this.$router.push('/search-results');
    },
    constructUrl(obj) { // Creates API call URL
      let url = '';
      // eslint-disable-next-line camelcase
      const form_data = Object.entries(obj);
      // eslint-disable-next-line consistent-return
      form_data.forEach((item) => {
        if (item[1] && (!Array.isArray(item[1])
           || (Array.isArray(item[1]) && item[1].length > 0))) {
          // console.log(item);
          if (item[0] === 'w_wet') { // Concatenate W & Wet
            url += 'w=';
            if (Array.isArray([item[1]])) {
              item[1].forEach((wet) => {
                url += combineWandWet(wet);
                url += ',';
              });
              // replace the final , with an &
              url = url.slice(0, -1);
              url += '&';
            } else {
              url += combineWandWet(item[1]);
              url += '&';
            }
          } else if (item[0] === 'status') { // Convert literal to abbreviation
            url += 'status=';
            if (Array.isArray(item[1])) {
              item[1].forEach((stat) => {
                url += translateStatus(stat);
                url += ',';
              });
              // replace the final , with an &
              url = url.slice(0, -1);
              url += '&';
            } else {
              url += translateStatus(item[1]);
              url += '&';
            }
          } else if (Array.isArray(item[1])) {
            url += (`${item[0]}=${item[1].join(',')}&`);
          } else {
            url += (`${item[0]}=${item[1].trim()
              .replace(/[\s]+/g, '%20')
              .trim()}&`);
          }
        }
      });
      // console.log(url);
      return url;
    },

    // Gets keys from initial formData object
    dataKeys(obj) {
      return Object.entries(obj)
        .reduce((arr, e) => {
          arr.push(e[0]);
          return arr;
        }, []);
    },
    formReset() {
      //console.log('flora search formReset');
      this.isSubmitted = false;
      this.noResults = false;
      Object.assign(this.formData, getInitialData());
    },
    formClear() {
      this.formReset();
      this.$refs.form.reset();
    },
    onEnter() {
      this.msg = 'on enter event';
    },
    ...mapActions('floraSearch', {
      searchLoaded: 'searchLoaded',
      setFloraResults: 'setFloraResults',
    }),
  },
  watch: {
    // For button animation
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },

  },
  computed: {
    tipsWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return '400px';
        default:
          return '400px';
      }
    },
    ...mapState('floraSearch', {
      openSearch: state => state.openSearch,
    }),
  },
};

function combineWandWet(selection) {
  return `${selection.split(' ')[0]}`;
}

function translateStatus(stat) {
  switch (stat) {
    case 'Extirpated':
      return 'X';
    case 'Endangered':
      return 'E';
    case 'Threatened':
      return 'T';
    case 'Special Concern':
      return 'SC';
    default:
      return 'Undefined';
  }
}

function getInitialData() {
  return {
    plant_id: '',
    scientific_name: '',
    c: '',
    w_wet: '',
    w: null,
    wet: '',
    phys: '',
    na: '',
    family: '',
    exact_match_family: false,
    genus: '',
    exact_match_genus: false,
    common_name: '',
    n_results: null,
    county: '',
    status: '',
    st: '',
    // county: null,
    // sort_first: null,
    // sort_first_direction: null,
    // sort_second: null,
    // sort_second_direction: null,
  };
}
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.form{
  max-width: max-content;
  margin-top: 10vh;
}

</style>
