<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop @formReset="formReset()" />
    <AlertMessage />
    <v-row class="mt-12">
      <!-- Space filler -->
    </v-row>
    <v-row class="mt-3">
      <!-- Space filler -->
    </v-row>
    <v-main>
      <v-row no-gutters align="center">
        <v-col lg="3" md="2" cols="1">
          <NavDrawer />
        </v-col>
        <v-col cols="10" lg="6" offset-lg="6" md="8" offset-md="5" offset-sm="4">
          <v-form v-on:keyup.enter.native="sendFormData" class="form mb-5" ref=form v-if="!isSubmitted">
            <div id="title" class="headline font-weight-regular">Specimen Search</div>
            <v-text-field v-model="formData.common_name"
                          label="Common Name"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.scientific_name"
                          label="Scientific Name"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.genus"
                          label="Genus"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.family"
                          label="Family"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.collector"
                          label="Collector's Name"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.collector_number"
                          label="Collector's Number"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.collection_year"
                          label="Collection Year"
                          dense
            ></v-text-field>
            <v-text-field v-model="formData.location"
                          label="Location"
                          dense
            ></v-text-field>
            <v-select v-model="formData.county"
                      :items="selectOptions.county_island_select"
                      label="County"
                      multiple
                      dense
                      clearable
            ></v-select>
            <span>
              <v-btn v-on:click="sendFormData"
                     @keyup.enter="sendFormData"
                     :loading="loading"
                     color="primary"
                     class="mt-4 ma-1"
                     @click="loader = 'loading'">Search</v-btn>
              <v-btn @click="formClear()"
                     class="mt-4 ma-1">Clear</v-btn>
              <v-dialog
                v-model="dialog"
                width="500"
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mt-4 ma-1"
                >
                  Search Tips
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  class="headline grey lighten-2"
                  primary-title
                >
                  Search Tips
                </v-card-title>

                <v-card-text class="mt-4 text-justify">
                  <ul>
                    <li class="mb-2 subtitle-1" style="color:black">Synonyms linking to the three volume Michigan Flora are included in the Scientific Name searches (but not the Genus searches), so entering the genus “Panicum” in the Scientific Name search field will also return species in the genus “Dichanthelium”</li>
                    <li class="mb-2 subtitle-1" style="color:black">A “%” can be used as a wildcard, so “Mu%gia” entered in the Genus field will return all the species in “Muhlenbergia”; “Cat%tail” entered in the Common Name field will return all species of “cat-tails”, which is useful when you’re unsure if the name is hyphenated or two words</li>
                    <li class="mb-2 subtitle-1" style="color:black">Some searches, such as Scientific Name or Collector, can be conducted on partial entries, so entering “bia cor” in the Scientific Name search field will return “Euphorbia corollata”; “Haz” entered in the Collector field will return collections of “A.S. Hazzard” and “Brian T. Hazlett”</li>
                    <li class="mb-2 subtitle-1" style="color:black">For the collection search, to select decades in the year field, use %, so 186%, will return all the collections from the decade of the 1860’s</li>
                    <li class="mb-2 subtitle-1" style="color:black">You can search by day if you place dates in the format: 05/27/1950 or month using a wildcard i.e., 05/%/1950</li>
                  </ul>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    Return to search
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            </span>
            <v-col class="mt-4" cols="12" v-if="noResults">
              <p class="title">No results found.</p>
            </v-col>
          </v-form>
          <v-overlay :value="loaderOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-col>
          <!--v-row class="mt-12">
            <v-col cols="11" lg="8" offset-lg="3" offset="1" class="mt-12" v-if="isSubmitted">
              <SpecimenResults @formReset="formReset()" :results="searchResponse"/>
            </v-col>
          </v-row-->
        </v-row>
      </v-main>
      <TheFooter />
    </v-container>
</template>

<script>
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import NavTop from '../../components/NavTop.vue';

export default {
  name: 'SpecimenSearch',
  components: {
    NavTop,
    NavDrawer,
    TheFooter,
    AlertMessage
  },
  data() {
    return {
      searchResponse: [],
      isSubmitted: false,
      noResults: false,
      isError: false,
      submitting: false,
      // eslint-disable-next-line no-use-before-define
      formData: getInitialData(),
      selectOptions: {
        county_island_select: ['Alcona', 'Alger', 'Allegan', 'Alpena', 'Antrim', 'Arenac',
          'Baraga', 'Barry', 'Bay', 'Benzie', 'Berrien', 'Branch', 'Calhoun', 'Cass',
          'Charlevoix', 'Cheboygan', 'Chippewa', 'Clare', 'Clinton',
          'Crawford', 'Delta', 'Dickinson', 'Eaton', 'Emmet', 'Genesee', 'Gladwin', 'Gogebic',
          'Grand Traverse', 'Gratiot', 'Hillsdale', 'Houghton', 'Huron', 'Ingham', 'Ionia',
          'Iosco', 'Iron', 'Isabella', 'Jackson', 'Kalamazoo', 'Kalkaska', 'Kent', 'Keweenaw',
          'Lake', 'Lapeer', 'Leelanau', 'Lenawee', 'Livingston',
          'Luce', 'Mackinac', 'Macomb', 'Manistee', 'Marquette',
          'Mason', 'Mecosta', 'Menominee', 'Midland', 'Missaukee', 'Monroe', 'Montcalm',
          'Montmorency', 'Muskegon', 'Newaygo', 'Oakland', 'Oceana', 'Ogemaw', 'Ontonagon',
          'Osceola', 'Oscoda', 'Otsego', 'Ottawa', 'Presque Isle', 'Roscommon', 'Saginaw',
          'Saint Joseph', 'Sanilac', 'Schoolcraft', 'Shiawassee', 'St. Clair', 'St. Joseph',
          'Tuscola', 'Van Buren', 'Washtenaw', 'Wayne', 'Wexford'],
        sort_first_select: ['Scientific Name (Ascending)', 'Scientific Name (Descending)', 'Family (Ascending)', 'Family (Descending)',
          'County (Ascending)', 'County (Descending)', 'Catalog Number (Ascending)', 'Catalog Number (Descending)'],
        sort_second_select: ['Scientific Name (Ascending)', 'Scientific Name (Descending)', 'Family (Ascending)', 'Family (Descending)',
          'County (Ascending)', 'County (Descending)', 'Catalog Number (Ascending)', 'Catalog Number (Descending)'],
      },
      loader: null,
      loading: false,
      loaderOverlay: false,
      dialog: false,
      dictionary: {
        attributes: {
          // custom attributes
        },
        custom: {
          select: {
            required: 'Select field is required',
          },
        },
      },
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async sendFormData() {
      const requestURL = this.constructUrl(this.formData);
      this.loaderOverlay = true;
      await this.$store.dispatch('specimenSearch/getSpecResults', requestURL);
      this.loaderOverlay = false;
      this.$router.push('/specimen-results');
    },
    constructUrl(obj) { // Creates API call URL
      let url = '';
      // eslint-disable-next-line camelcase
      const form_data = Object.entries(obj);
      // eslint-disable-next-line consistent-return
      form_data.forEach((item) => {
        if (item[1]) {
          //console.log(item);
          if (Array.isArray(item[1])) {
            url += (`${item[0]}=${item[1].join(',')}&`);
          } else {
            url += (`${item[0]}=${item[1].trim()
              .replace(/[\s]+/g, '%20')
              .trim()}&`);
          }
        }
      });
      return url;
    },
    formReset() {
      this.isSubmitted = false;
      this.noResults = false;
      Object.assign(this.formData, getInitialData());
    },
    formClear() {
      this.formReset();
      this.$refs.form.reset();
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
};

function getInitialData() {
  return {
    plant_id: '',
    common_name: '',
    scientific_name: '',
    genus: '',
    family: '',
    species: '',
    subspecies: '',
    variety: '',
    catalog_number: '',
    catalog_path: '',
    catalog_data: '',
    collector: '',
    collectors: '',
    collector_number: '',
    collectors_number: '',
    accompanying_collectors: '',
    collection_date: '',
    collection_year: '',
    determiner1: '',
    determiner2: '',
    determiner3: '',
    determination_history: '',
    county: '',
    location: '',
    place_name: '',
    locality: '',
    location_data: '',
    latitude: '',
    longitude: '',
    habitat: '',
    herbaria: '',
    n_results: null,
    // county: null,
    // sort_first: null,
    // sort_first_direction: null,
    // sort_second: null,
    // sort_second_direction: null,
  };
}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

.form{
  margin-top: 10vh;
  max-width: max-content;
 }

  #title{
    margin: 2vh;
  }

  #drawer-container{
    margin-top: 15vh;
    position: relative;
  }
</style>
