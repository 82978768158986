<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <NavDrawer />
    <v-main class="mt-12">
      <v-col class="text-justify" cols="10" offset-lg="3" lg="8" offset="1">
      <v-row class="mt-12">
        <v-btn href='/admin/bulk-upload'>Bulk Image Uploader</v-btn>
      </v-row>
      <v-row class="mt-12">
        <v-btn href='/admin/add-species/'>Add Species</v-btn>
      </v-row>
        <v-row class="mt-12">
          <v-btn href='/admin/add-genus/'>Add Genus</v-btn>
        </v-row>
      </v-col>
      <TheFooter />
    </v-main>
  </v-container>
</template>

<script>
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';

export default {
  name: 'AdminPage',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    TheFooter,
  },
};
</script>

<style>
  @import '../../css/HomeTemplate.css';
</style>
