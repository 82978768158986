<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <v-main class="mt-8">
      <v-row class="mt-8">
        <v-col lg="3" md="1" cols="1">
          <!-- Fills extra space in the row -->
        </v-col>
        <v-col cols="10" lg="8" sm="10" class="mt-12 text-justify">
          <p :class="{'display-1': $vuetify.breakpoint.lgAndUp, 'headline': $vuetify.breakpoint.mdAndDown}">
            {{ this.$route.meta.title }}</p>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col lg="3" md="1" cols="1">
          <NavDrawer />
        </v-col>
        <v-col cols="10" lg="8" offset-lg="3" sm="10" class="mt-4 text-justify font-weight-regular">
          <HomeTemplate :pageID="this.$route.meta.pageID" :pageTitle="this.$route.meta.title"></HomeTemplate>
        </v-col>
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import HomeTemplate from '@/views/home/HomeTemplate.vue';

export default {
  name: 'TextPage',
  components: {
    NavTop,
    NavDrawer,
    TheFooter,
    AlertMessage,
    HomeTemplate,
  },
  data() {
    return {
      title: this.$route.meta.title,
    };
  },
};
</script>

<style scoped>

</style>
