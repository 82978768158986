import axios from 'axios';

export const specimenSearch = {
  namespaced: true,
  state: {
    openSearch: false,
    searchResults: [],
    noResults: false,
  },
  mutations: {
    pushSpecResults(state, results) {
      state.searchResults = results;
      state.noResults = results.length === 0;
    },
    specSearchLoading(state) {
      //console.log('set openSearch to true');
      state.openSpecSearch = true;
      // console.log('done');
    },
    specSearchLoaded(state) {
      state.openSpecSearch = false;
      //console.log('set openSearch to false');
    },
  },
  actions: {
    async getSpecResults({ commit }, requestURL) {
      const path = '/api/v1.0/spec_search_sp?n_results=0&';
      const request = path.concat(requestURL);
      this.loaderOverlay = true;
      return axios.get(request)
        .then((response) => {
          // data.message = Null if there are no errors in the api call
          if (response.data.message == null) {
            // .responseFilter(response.data); see comment below seems to do nothing
            // this.searchResponse = response.data;
            //console.log(this.searchResponse);
            //console.log(response.data);
            this.loaderOverlay = false;
            commit('pushSpecResults', response.data);
            commit('specSearchLoaded');
          } else {
            commit('pushSpecResults', []);
            //console.log('pushing empty results');
            commit('specSearchLoaded');
            this.loaderOverlay = false;
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.loaderOverlay = false;
        });
    },

    specSearchLoading({ commit }) {
      commit('specSearchLoading');
    },
    specSearchLoaded({ commit }) {
      commit('specSearchLoaded');
    },
  },
};
