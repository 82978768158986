<template>
  <v-container fluid v-if="alert_message" class="announcement-container ma-2">
      <v-col class="text-justify announcement-col" cols="10" offset-lg="3" lg="8" offset="1">
        <div class="announcement">
          {{ alert_message }}
        </div>
      </v-col>
  </v-container>
  <v-row v-else class="mt-8"></v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AlertMessage',
  data() {
    return {
      alert: undefined,
    };
  },
  created() {
    this.$store.dispatch('alertMessage/getAlertMessage');
  },
  computed: {
    ...mapState('alertMessage', {
      alert_message: state => state.alert_message,
    }),
  },
};
</script>

<style scoped>
.announcement-container {
  position: relative;
  top: 95px;
}
.announcement-col {
  padding:0;
}
.announcement {
  background: #ffecec;
  color: #a20000;
  border: 1px solid #a20000;
  border-radius: 10px;
  padding: 1% 30px 1% 30px;
  box-sizing: border-box;
}

</style>
