import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const alertMessage = {
  namespaced: true,
  state: {
    alert_message: '',
  },
  actions: {
    async getAlertMessage({ commit }) {
      await axios.get('/api/v1.0/homepages_text?id=alert') // /auth/getuser
        .then((resp) => {
          commit('pushAlertMessage', resp.data.text);
        }).catch((error) => {
          console.error(error);
        });
    },
  },
  mutations: {
    pushAlertMessage(state, value) {
      state.alert_message = `${value}`;
    },
  },
  getters: {
    alertMessage(state) {
      return state.alert_message;
    },
  },
};
