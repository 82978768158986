<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <NavDrawer />
    <v-main class="mt-12">
      <v-col class="text-justify" cols="10" offset-lg="3" lg="8" offset="1">
        <v-row class="mt-12 ml-12">
          <div style="width: 100%;"><h1>Add Genus</h1></div>

          <div>
            Genus*
            <v-text-field title="genus" v-model="form['genus']" dense />

            Family*
            <v-autocomplete title="Family"
                            v-model="form['family']"
                            :items="family_values"
                            dense />
          </div>
          <div style="width: 100%;; margin: 4px;">
            Genus Text* (Can be edited later with "Edit Text" option)
            <ckeditor :key="editorConfig.editorKey"
                      v-model="form['genus_text']"
                      :config="editorConfig"
                      :editor-url="editorConfig.editorUrl"
            ></ckeditor>
          </div>
        </v-row>
        <v-row>
          <div class="species-submission-btn-container">
            <v-btn @click="submit_species" id="species-submission-button">Submit</v-btn>
          </div>
        </v-row>
      </v-col>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import axios from 'axios';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import ckeditor from '../../../node_modules/ckeditor4-vue/dist/ckeditor';


export default {
  name: 'AddSpecies',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    TheFooter,
    ckeditor: ckeditor.component,
  },
  data() {
    return {
      title: 'Add Species',
      form: {
        genus: null,
        family: null,
        genus_text: null,
      },
      family_values: [],
      editorConfig: {
        allowedContent: true, // For HTML source editing
        editorUrl: 'https://lsats-rci-research.github.io/flora-ckeditor4/ckeditor.js',
        // Key for editor reset: https://michaelnthiessen.com/force-re-render/
        editorKey: 1,
        extraPlugins: 'codemirror',
      },
    };
  },
  async beforeMount() {
    const response = await axios.get('/api/v1.0/family_list');
    this.family_values = response.data.families;
  },
  methods: {
    async submit_species() {
      const textObj = JSON.stringify(this.form);

      if (!this.validate_form()) {
        window.alert('Required field is still blank.');
        return;
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        await axios.post('/admin/add/genus', textObj, config);
        console.log('why are we here');
        window.location.href = `/genus/${this.form.genus}`;
      } catch {
        window.alert('Error adding Genus. Refreshing page.');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    },
    validate_form() {
      return !!(this.form.genus
        && this.form.family
        && this.form.genus_text);
    },
  },
  // watch: {
  //   'form.family': async function (val) {
  //     console.log(val);
  //     const response = await axios.get(`/api/v1.0/genus_list?family=${val}`);
  //     this.genus_values = response.data.genera;
  //   },
  // },
};
</script>

<style>
@import '../../css/HomeTemplate.css';

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap:8px;
}

.grid-item {
  background-color: #EEEEEE;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
}

.species-submission-btn-container {
  text-align: center;
  width: 100%;
  margin: 8px;
}
</style>
