import axios from 'axios';
// import vueLazyloadEsm from 'vue-lazyload';

export const floraSearch = {
  namespaced: true,
  state: {
    openSearch: false,
    searchResults: [],
    noResults: false,
  },
  mutations: {
    pushFloraResults(state, results) {
      state.searchResults = results;
      state.noResults = results.length === 0;
    },
    searchLoading(state) {
      //console.log('set openSearch to true');
      state.openSearch = true;
      // console.log('done');
    },
    searchLoaded(state) {
      state.openSearch = false;
      //console.log('set openSearch to false');
    },
  },
  actions: {
    async getFloraResults({ commit }, requestURL) {
      const path = '/api/v1.0/flora_search_sp?n_results=0&';
      const request = path.concat(requestURL);
      this.loaderOverlay = true;
      return axios.get(request)
        .then((response) => {
          // data.message = Null if there are no errors in the api call
          if (response.data.message == null) {
            // .responseFilter(response.data); see comment below seems to do nothing
            // this.searchResponse = response.data;
            //console.log(this.searchResponse);
            //console.log(response.data);
            this.loaderOverlay = false;
            commit('pushFloraResults', response.data);
            commit('searchLoaded');
          } else {
            commit('pushFloraResults', []);
            //console.log('pushing empty results');
            commit('searchLoaded');
            this.loaderOverlay = false;
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.loaderOverlay = false;
        });
    },
    searchLoading({ commit }) {
      commit('searchLoading');
    },
    searchLoaded({ commit }) {
      commit('searchLoaded');
    },
  },
};
