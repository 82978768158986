<template>
 <div>
   <h1>Page Not Found</h1>
   <p>The page you're looking for does not exist.<br>
     If the page URL contains .aspx, you are using an outdated link.<br>
     Please go to <a href="https://michiganflora.net">michiganflora.net</a> and navigate to your desired location from there.
   </p>
 </div>
</template>

<script>
export default {
  name: 'NotFound.vue'
};
</script>

<style scoped>

</style>
