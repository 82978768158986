<template>
  <v-container>
    <v-main>
      <v-row justify-sm="center" class="anchors" :class="{'ml-1': $vuetify.breakpoint.smAndDown}">
        <a v-for="(value, key) in browseList" class="title ma-1 button" :href="'#browse-'+key">{{ key }}</a>
      </v-row>
      <v-row justify-sm="center" class="fill-width">
        <v-col cols="11" lg="8" offset-lg="2" md="10" offset="1" class="text-justify text-center mt-5">

          <div v-for="(value,key) in browseList">
            <p :class="{'headline': $vuetify.breakpoint.lgAndUp, 'title': $vuetify.breakpoint.mdAndDown}">
              {{ key }}
            </p>
            <v-divider class="mb-3"></v-divider>
            <span class="anchor" :id="'browse-'+key"></span>
            <v-row class="browse-links mb-4 justify-start">
              <router-link v-for="(item) in value" class="browse" :to="{ name: browseType, params: {[browseType + '_name']: item}}">
                {{ item }}
              </router-link>
            </v-row>
            <p>&nbsp;</p>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>

export default {
  name: 'BrowseList',
  props: {
    browseList: Object,
    browseType: String,
  },
};
</script>

<style scoped>

  .browse {
    padding: 0.5em;
    display: inline-block;
    width: 10em;
  }
  .browse:hover{
    font-weight: bold;
  }
  .title:hover{
    font-weight: bold;
  }
  .anchors {
    display: flex;
    max-width: 100%;
  }
  .anchor {
    margin-top: -175px;
    padding-bottom: 175px;
    display: inline-block;
  }

</style>
