<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <NavDrawer />
    <v-main class="mt-12">
      <v-row class="mt-12"></v-row>
      <v-row class="mt-0" id="map-container">
          <v-col cols="12" lg="8" offset-lg="3"  >
            <iframe  :src="this.mapURL" class="ml-9 mt-1 mr-4 mb=1"></iframe>
          </v-col>
      </v-row>
      <TheFooter />
    </v-main>
  </v-container>
</template>

<script>
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';

export default {
  name: 'FloraMap',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    TheFooter,
  },
  data: () => ({
    mapURL: `${process.env.VUE_APP_PUBLIC_PATH}static/mi-flora-webmap/specimen_map.html`,
  }),
};
</script>

<style scoped>
  #map-container >>> iframe {
    width: 90%;
    height: 73vh;
  }
  @media only screen and (min-height: 700px) {
    #map-container >>> iframe {
      height: 78vh;
    }
  }
</style>
