<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <v-row class="mt-12">
      <!-- Margin top -->
    </v-row>
    <v-row class="mt-12">
      <!-- Margin top -->
    </v-row>
    <NavDrawer />
    <v-main class="mt-12">
      <v-row justify="center">
        <v-col cols="12" lg="10" offset-lg="2">
          <v-item-group id="browse-buttons">
            <v-btn-toggle v-model="toggle" mandatory>
              <v-btn rounded color="primary" class="white--text elevation-2"
                     @click="updateRoute">
                Browse by Family
              </v-btn>
              <v-btn rounded color="primary" class="white--text elevation-2"
                     @click="updateRoute">
                Browse by Genus
              </v-btn>
            </v-btn-toggle>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="10" offset-lg="2">
          <v-fade-transition>
            <BrowseList v-if="toggle===0" class="mt-5"
              :browse-list="this.familyValues"
              browse-type="family"></BrowseList>
            <BrowseList v-else class="mt-5"
              :browse-list="this.genusValues"
              browse-type="genus"></BrowseList>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import BrowseList from '@/views/browse/BrowseList.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import axios from 'axios';
import router from '../../router';

export default {
  name: 'TheBrowser',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    BrowseList,
    TheFooter,
  },
  data() {
    return {
      toggle: 0,
      genusValues: {},
      familyValues: {},
    };
  },
  async beforeMount() {
    const sortByFirstLetter = (nameList) => {
      return nameList.reduce((obj, item) => {
        const key = item[0].toUpperCase();
        obj[key] = obj[key] || [];
        obj[key].push(item);
        return obj;
      }, {});
    };
    let response = await axios.get('/api/v1.0/genus_list');
    this.genusValues = sortByFirstLetter(response.data.genera);
    response = await axios.get('/api/v1.0/family_list');
    this.familyValues = sortByFirstLetter(response.data.families);
  },
  methods: {
    updateRoute() {
      /* If the user switches between Family & Genus view, navigate to the base Browse page
        /Browse instead of whatever hashed location they were at eg. /Browse#browse-S. This is so
        if they click a letter to browse to that section, then scroll back to the top of the page
        and switch to the other category (family/genus), then click a name to go to a family/genus
        page, then click the back button, they don't get taken to the section they had previously
        clicked on and scrolled away from.
       */
      if (router.currentRoute.hash) {
        router.replace('Browse');
      }
    },
  },
};
</script>

<style scoped>
  .mt-12{
    min-height: 1px;
  }

</style>
<style>
  #browse-buttons{
    z-index: 1;
  }

  .v-btn-toggle > .v-btn.v-btn--active{
    background-color: #001426 !important;
  }


</style>
