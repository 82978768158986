<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <AlertMessage />
    <NavDrawer />
    <v-main class="mt-12 mb-14">
      <v-col class="text-justify" cols="10" offset-lg="3" lg="8" offset="1">
        <v-row class="mt-12 ml-12">
          <div style="width: 100%;"><h1>Add Species</h1></div>

          <div>
            Species*
            <v-text-field title="species" v-model="form['species']" dense />

            Family*
            <v-autocomplete title="Family"
                            v-model="form['family']"
                            :items="family_values"
                            dense />

            Genus*
            <v-autocomplete title="Genus"
                            v-model="form['genus']"
                            :items="genus_values"
                            dense />

            Common Name*
            <v-text-field title="Common Name" v-model="form['common_names']" dense />
          </div>

          <div class="grid-container">
            <div class="grid-item">
              Acronym
              <v-text-field title="Acronym" v-model="form['acronym']" dense />
            </div>

            <div class="grid-item">
              Coefficient of Conservatism
              <v-select
                title="Coefficient of Conservatism"
                v-model="form['coeff_of_conservatism']"
                :items="coeff_of_conservatism_values"
              />
            </div>

            <div class="grid-item">
              Author
              <v-text-field title="author" v-model="form['author']" dense />
            </div>

            <div class="grid-item">
              Native/Adventive
              <v-select
                title="n_a"
                v-model="form['n_a']"
                :items="n_a_values"
                dense
              />
            </div>

            <div class="grid-item">
              Status
              <v-select title="status" v-model="form['status']" :items="status_values" dense />
            </div>

            <div class="grid-item">
              Wetness
              <v-select
                title="Wetness Index"
                v-model="form['wetness']"
                :items="wetness_values"
                dense />
            </div>

            <div class="grid-item">
              Physiognomy
              <v-autocomplete
                title="Physiognomy"
                v-model="form['physiognomy']"
                :items="physiognomy_values"
                dense />
            </div>

            <div class="grid-item">
              Family Common Name
              <v-text-field title="Family Common Name" v-model="form['fam_common_name']" dense />
            </div>
          </div>
          <div style="width: 100%; margin-top: 10px; margin-bottom: 10px">
            <h2>
              Synonym
            </h2>
          </div>
          <div>
            Synonym Species
            <v-text-field title="Synonym Species" v-model="form['syn_species']" dense />
            Synonym Genus
            <v-text-field title="Synonym Genus" v-model="form['syn_genus']" dense />
          </div>
          <div style="width: 100%; margin: 4px;">
            Synonym Notes
            <v-textarea title="Synonym Notes" v-model="form['syn_notes']" rows="1" dense />
          </div>
        </v-row>
        <v-row class="mb-14">
          <div class="species-submission-btn-container">
            <v-btn @click="submit_species" id="species-submission-button">Submit</v-btn>
          </div>
        </v-row>
      </v-col>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import axios from 'axios';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';
import AlertMessage from '@/components/AlertMessage.vue';

export default {
  name: 'AddSpecies',
  components: {
    AlertMessage,
    NavTop,
    NavDrawer,
    TheFooter,
  },
  data() {
    return {
      title: 'Add Species',
      form: {
        family: null,
        genus: null,
        acronym: null,
        coeff_of_conservatism: null,
        species: null,
        author: null,
        n_a: null,
        status: null,
        wetness: null,
        physiognomy: null,
        common_names: null,
        fam_common_name: null,
        syn_species: null,
        syn_genus: null,
        syn_notes: null,
      },
      coeff_of_conservatism_values: ['*', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      n_a_values: ['N', 'A'],
      wetness_values: ['-5 OBL', '-3 FACW', '0 FAC', '3 FACU', '5 UPL'],
      status_values: ['Extirpated', 'Endangered', 'Threatened', 'Special Concern'],
      physiognomy_values: ['Ad Fern', 'Ad A-Forb', 'Ad B-Forb', 'Ad P-Forb', 'Ad A-Grass', 'Ad P-Grass', 'Ad A-Sedge', 'Ad P-Sedge', 'Ad Shrub', 'Ad Tree', 'Ad A-Vine', 'Ad P-Vine',
        'Ad W-Vine', 'Nt Fern', 'Nt Fern Al', 'Nt A-Forb', 'Nt B-Forb', 'Nt P-Forb', 'Nt A-Grass', 'Nt P-Grass', 'Nt A-Sedge', 'Nt P-Sedge', 'Nt Shrub', 'Nt Tree', 'Nt A-Vine',
        'Nt B-Vine', 'Nt P-Vine', 'Nt W-Vine'],
      family_values: [],
      genus_values: [],
      all_genera: [],
    };
  },
  async beforeMount() {
    let response = await axios.get('/api/v1.0/family_list');
    this.family_values = response.data.families;

    response = await axios.get('/api/v1.0/genus_list');
    this.genus_values = response.data.genera;
  },
  methods: {
    async submit_species() {
      const textObj = JSON.stringify(this.form);

      if (!this.validate_form()) {
        window.alert('A required field is still blank.');
        return;
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const resp = await axios.post('/admin/add/species', textObj, config);
      if (resp.data.error) {
        window.alert(`Error: ${resp.data.error}`);
      } else {
        window.location.href = `/record/${resp.data.plant_id}`;
      }
    },
    validate_form() {
      // Require species, genus, family, common name
      // Also if there is a synonym species, require a synonym genus
      if ((this.form.species
        && this.form.genus
        && this.form.family
        && this.form.common_names)
        && ((!this.form.syn_species && !this.form.syn_genus)
        || (this.form.syn_species && this.form.syn_genus))) {
        return true;
      }
      return false;
    },
  },
  watch: {
    'form.family': async function (val) {
      console.log(val);
      const response = await axios.get(`/api/v1.0/genus_list?family=${val}`);
      this.genus_values = response.data.genera;
      this.all_genera = response.data.genera;
    },
  },
};
</script>

<style>

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap:8px;
}

.grid-item {
  background-color: #EEEEEE;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
}

.species-submission-btn-container {
  text-align: center;
  width: 100%;
  margin: 8px;
}
</style>
