<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row id="footer">
      <v-col class="pa-0">
        <v-footer app dark color="primary" class="mt-5 elevation-6">
          <v-col cols="1" v-if="desktop">
            <div class="body-2 mr-5" v-if="authenticated">{{ email }}</div>
            <v-btn x-small color="primary" href="/auth/logout" v-if="authenticated">Logout</v-btn>
            <v-btn x-small color="primary" href="/auth/login" v-else>Login</v-btn>
<!--        For local testing, change to:
            <v-btn x-small color="primary" href="http://localhost:5050/auth/login" v-else>Login</v-btn>-->
            <v-btn x-small color="primary" href="/admin" v-if="admin">ADMIN</v-btn>
          </v-col>
          <v-col class="ma-0 pa-0">
            <div v-if="desktop" class="text-body-2"><a href="http://herbarium.lsa.umich.edu">University of Michigan Herbarium</a> | 3600 Varsity Drive | Ann Arbor, MI 48108-2228 | <a href="mailto:eeb-michiganfloraonline@umich.edu">eeb-michiganfloraonline@umich.edu</a> | phone 734.615.6200 | fax 734.998.0038</div>
            <div v-else class="text-caption"><a href="http://herbarium.lsa.umich.edu">University of Michigan Herbarium</a><br><a href="mailto:eeb-michiganfloraonline@umich.edu">eeb-michiganfloraonline@umich.edu | phone 734.615.6200</a></div>
          </v-col>
        </v-footer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TheFooter',
  data() {
    return {
      currentUser: '',
    };
  },
  created() {
    this.$store.dispatch('currentUser/getCurrentUser');
  },
  computed: {
    ...mapState('currentUser', {
      authenticated: state => state.authenticated,
      email: state => state.email,
      admin: state => state.admin,
    }),
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },

  },
};
</script>

<style scoped>
  a {
    color: ivory !important;
  }

  #footer {
    transition: none !important;
  }

</style>
