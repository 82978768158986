import Vue from 'vue';

/* This component renders text from the database,
 which wouldn't render properly otherwise.
 This is because the text from the database has html formatting, which
 must be incorporated into the template itself, not simply rendered
 on the page in a text slot. */

export default Vue.component('DynamicText', {
  name: 'DynamicText',
  functional: true,
  props: {
    template: String,
    data: { type: Object, default: () => ({}) },
  },
  render(h, context) {
    // console.log("rendering dynamic text");
    const template = context.props.template;
    const dynComponent = {
      template,
      data() { return context.props.data; },
    };
    /* Adding the following section as shown here:
    https://dev-qa.com/1802742/how-correctly-handle-events-when-working-with-axios-and-php?show=1802743#a1802743
    There's really no explanation there that's helpful, but it does show what was
    missing from the code Adam used here: handling rendering
    before the data has loaded. This prevents the console errors that were happening
    otherwise.
     */
    const component = template
      ? dynComponent
      : {
        template: `<div> </div>`
      };
    return h(component);
  },
});
