import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Router from 'vue-router';
import FloraMap from '@/views/home/pages/FloraMap.vue';
import TextPage from '@/views/home/pages/TextPage.vue';
import TheBrowser from '@/views/browse/TheBrowser.vue';
import FamilyTemplate from '@/views/templates/Family&Genus/FamilyTemplate.vue';
import GenusTemplate from '@/views/templates/Family&Genus/GenusTemplate.vue';
import FloraSearch from '@/views/search/FloraSearch.vue';
import FloraResults from '@/views/search-results/FloraResults.vue';
import FloraRecord from '@/views/templates/FloraRecord/FloraRecord.vue';
import SpecimenSearch from '@/views/search/SpecimenSearch.vue';
import SpecimenResults from '@/views/search-results/SpecimenResults.vue';
import SpecimenRecord from '@//views/templates/SpecimenRecord.vue';
import LightGallery from '@/components/LightGallery.vue';
import EditFamily from '@/views/admin/EditFamily.vue';
import EditGenus from '@/views/admin/EditGenus.vue';
import EditSpecies from '@/views/admin/EditSpecies.vue';
import EditHome from '@/views/admin/EditHome.vue';
import ImageManager from '@/views/admin/Image Manager/ImageManager.vue';
import BulkUpload from '@/views/admin/bulk-upload/BulkUpload.vue';
import AddSpecies from '@/views/admin/AddSpecies.vue';
import AddGenus from '@/views/admin/AddGenus.vue';
import NotFound from '@/components/NotFound.vue';
import AdminPage from '@/views/admin/AdminPage.vue';
import store from '../_store/index';

Vue.use(Router);

const router = new Router({
  name: 'router',
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // console.log(savedPosition);
    if (savedPosition && savedPosition.y !== 0) {
      // console.log('saved position');
      return savedPosition;
    }
    if (to.hash) {
      console.log('hash');
      console.log(to.hash);
      /*
      Vue.nextTick(() => {
        /* TODO: Revisit this.
        Note that on large pages like Carex, this still happens before the content has loaded,
        so the target dom element isn't founded.
        Setting the family & genus pages to keep alive seems to help, but for some reason seems to
        also have the side effect of making their initial loads EXTREMELY slow.
       */ /*
        console.log(document.getElementsByName(to.hash.substring(1)));
        if (document.getElementsByName(to.hash.substring(1)).length > 0) {
          console.log(document.getElementsByName(to.hash.substring(1)));
          document.getElementsByName(to.hash.substring(1))[0].scrollIntoView();
        } else if (document.getElementById(to.hash.substring(1))) {
          document.getElementById(to.hash.substring(1)).scrollIntoView();
        }
      });
      */
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: TextPage,
      meta: {
        title: 'Introduction', pageID: 'HomeIntro',
      },
    },
    {
      path: '/browse',
      name: 'Browse',
      component: TheBrowser,
      meta: { keepAlive: true },
    },
    {
      path: '/glossary',
      name: 'Glossary',
      component: TextPage,
      meta: {
        title: 'The Glossary', pageID: 'TheGlossary',
      },
    },
    {
      path: '/links',
      name: 'Links',
      component: TextPage,
      meta: {
        title: 'Links', pageID: 'TheLinks',
      },
    },
    {
      path: '/references',
      name: 'References',
      component: TextPage,
      meta: {
        title: 'References', pageID: 'TheReferences',
      },
    },
    {
      path: '/flora-map',
      name: 'Flora Map',
      component: FloraMap,
    },
    {
      path: '/family-key',
      name: 'Family Key',
      component: TextPage,
      meta: {
        title: 'Family Key', pageID: 'FamilyKey',
      },
    },
    {
      path: '/information',
      name: 'Information',
      component: TextPage,
      meta: {
        title: 'Information', pageID: 'TheInformation',
      },
    },
    {
      path: '/maps',
      name: 'Maps',
      component: TextPage,
      meta: {
        title: 'The Maps', pageID: 'TheMaps',
      },
    },
    {
      path: '/map-completeness',
      name: 'MapCompleteness',
      component: TextPage,
      meta: {
        title: 'Completeness of the County Level Maps', pageID: 'MapCompleteness',
      },
    },
    {
      path: '/aliens',
      name: 'Aliens',
      component: TextPage,
      meta: {
        title: 'Aliens', pageID: 'TheAliens',
      },
    },
    {
      path: '/photos',
      name: 'Photos',
      component: TextPage,
      meta: {
        title: 'The Photos', pageID: 'ThePhotos',
      },
    },
    {
      path: '/changes',
      name: 'Changes',
      component: TextPage,
      meta: {
        title: 'Taxonomic & Nomenclatural Changes', pageID: 'TheChanges',
      },
    },
    {
      path: '/ferns',
      name: 'Ferns',
      component: TextPage,
      meta: {
        title: 'Ferns & Other Spore-Bearing Vascular Plants', pageID: 'TheFerns',
      },
    },
    {
      path: '/contribute',
      name: 'Contribute',
      component: TextPage,
      meta: {
        title: 'Contributing to the Website', pageID: 'ToContribute',
      },
    },
    {
      path: '/acknowledgments',
      name: 'Acknowledgments',
      component: TextPage,
      meta: {
        title: 'Acknowledgements', pageID: 'TheAcknowledgments',
      },
    },
    {
      path: '/family-changes',
      name: 'Family Changes',
      component: TextPage,
      meta: {
        title: 'Genera in Michigan Flora whose family assignments are here changed', pageID: 'FamilyChanges',
      },
    },
    {
      path: '/flora-changes',
      name: 'Flora Changes',
      component: TextPage,
      meta: {
        title: 'Additions and Name Changes to Michigan Flora', pageID: 'FloraChanges',
      },
    },
    {
      path: '/search',
      name: 'Search',
      component: FloraSearch,
      meta: { keepAlive: true },
    },
    {
      path: '/search-results',
      name: 'Flora Results',
      component: FloraResults,
    },
    {
      path: '/record/:id',
      name: 'Flora Record',
      component: FloraRecord,
      props: {
        default: true,
      },
    },
    {
      path: '/specimen-search',
      name: 'Specimen Search',
      component: SpecimenSearch,
      meta: { keepAlive: true },
    },
    {
      path: '/specimen-results',
      name: 'Specimen Results',
      component: SpecimenResults,
    },
    {
      path: '/specimen-record/:catalog_path',
      name: 'Specimen Record',
      component: SpecimenRecord,
      props: {
        default: true,
      },
    },
    {
      path: '/family/:family_name',
      name: 'family',
      component: FamilyTemplate,
      props: {
        default: true,
      },
    },
    {
      path: '/genus/:genus_name',
      name: 'genus',
      component: GenusTemplate,
      props: {
        default: true,
      },
    },
    {
      path: '/light-gallery',
      name: 'light-gallery',
      component: LightGallery,
    },
    {
      path: '/admin/edit-family/:family_name',
      name: 'Edit Family',
      component: EditFamily,
      props: {
        default: true,
      },
    },
    {
      path: '/admin/',
      name: 'Administrative Functions',
      component: AdminPage,
    },
    {
      path: '/admin/bulk-upload/',
      name: 'Bulk Upload',
      component: BulkUpload,
    },
    {
      path: '/admin/add-species',
      name: 'Add Species',
      component: AddSpecies,
    },
    {
      path: '/admin/add-genus',
      name: 'Add Genus',
      component: AddGenus,
    },
    {
      path: '/admin/edit-genus/:genus_name',
      name: 'Edit Genus',
      component: EditGenus,
      props: {
        default: true,
      },
    },
    {
      path: '/admin/edit-species/:id',
      name: 'Edit Species',
      component: EditSpecies,
      props: {
        default: true,
      },
    },
    {
      path: '/admin/edit-home/:id',
      name: 'Edit Home Page',
      component: EditHome,
      props: {
        default: true,
      },
    },
    {
      path: '/admin/fm/species-images/:id',
      name: 'Image Manager',
      component: ImageManager,
      props: route => ({
        default: true,
        path: route.query.path,
      }),
      meta: { keepAlive: true },
    },
    {
      path: '/*.aspx',
      redirect: '/:pathMatch',
    },
    {
      path: '/:pathMatch(.*)*',
      beforeEnter(to, from, next) {
        if (to.path === '/genus' && to.query.id) {
          next(`/genus/${to.query.id}`);
        } else if (to.path === '/family' && to.query.id) {
          next(`/family/${to.query.id}`);
        } else if (to.path === '/species' && to.query.id) {
          next(`/record/${to.query.id}`);
        } else {
          next();
        }
      },
      name: 'NotFound',
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // console.log(from.name);
  if (to.name === 'Search' && from.name !== 'Flora Record') { // why the @#*% is there a space in that name??
    store.commit('floraSearch/searchLoading');
  }
  next();
});

export default router;
