<template>
  <v-col cols="12" lg="9" offset-lg="3"  xs="11">
    <v-row class="ml-10 ml-lg-0 ml-xl-0" align-content="space-around" >
      <v-col cols="12" sm="7" class="ma-0 mr-6 text-left">
        <p v-if="this.multipleEntries">
          <template >
            <span class="font-weight-bold">Common Name:</span> {{ flora_data[0].common_name.join(", ") }} <br>
            <span class="font-weight-bold">Coefficient of Conservatism:</span> {{ varietyA.c }} or {{ varietyN.c }} if a native occurrence<br>
            <span class="font-weight-bold">Coefficient of Wetness:</span> {{ varietyA.w }} or {{ varietyN.w }} if a native occurrence<br>
            <span class="font-weight-bold">Wetness Index:</span> {{ varietyA.wet }} or {{ varietyN.wet }} if a native occurrence<br>
            <span class="font-weight-bold">Physiognomy:</span> {{ varietyA.phys }} or {{ varietyN.phys }}<br>
            <span v-if="varietyA.st !== 'NULL'"><b>Status:</b> {{ varietyA.st }} if an adventive occurrence</span> <br>
            <span v-if="varietyN.st !== 'NULL'"><b>Status:</b> {{ varietyN.st }} if a native occurrence</span> <br>
          </template>
        </p>
        <p v-else-if="this.flora_data.loaded">
          <template v-for="flora in this.flora_data">
            <span class="font-weight-bold">Common Name:</span> {{ flora.common_name.join(", ") }} <br>
            <span class="font-weight-bold">Coefficient of Conservatism:</span> {{ flora.c }} <br>
            <span class="font-weight-bold">Coefficient of Wetness:</span> {{ flora.w }} <br>
            <span class="font-weight-bold">Wetness Index:</span> {{ flora.wet }} <br>
            <span class="font-weight-bold">Physiognomy:</span> {{ flora.phys }} <br>
            <span v-if="flora.st !=='NULL'"><b>Status:</b> {{ flora.st }}</span> <br>
          </template>
        </p>
        <!--
        mounts DynamicText
        -->
        <slot></slot>
        <!--
        mounts DynamicText
        -->
        <router-link v-if="admin"
                     class="body-1 edit-link float-right mt-2"
                     :to="{ path: '/admin/edit-species/' + $route.params.id, params: { id: $route.params.id }}"
                     target="_blank">Edit Text</router-link>
      </v-col>
      <v-col cols="11" sm="4" class="ma-0">
        <v-hover>
          <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" class="mx-auto">
            <v-img :src="priorityImageSrcHover ? priorityImageSrcHover : imagesFilter.length ? imagesFilter[0].src : placeholderUrl"
                   @click="showGallery"
                   class="elevation-3"
                   style="cursor: pointer"
                   :alt="priorityImageSrcHover ? priorityImageCaption : imagesFilter.length ? imagesFilter[0].caption : 'Using placeholder image'">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
        <p v-if="priorityImageSrcHover && priorityImagePhotographer" class="mt-3 caption text-center">{{ priorityImagePhotographer }}</p>
        <p v-else-if="!priorityImageSrcHover && imagesFilter.length && imagesFilter[0].photographer" class="mt-3 caption text-center">Photo: {{ imagesFilter[0].photographer }}</p>
        <p v-if="priorityImageSrcHover && priorityImagePhotographer" class="mt-3 caption text-center">Click image to view gallery</p>
        <p v-else-if="!priorityImageSrcHover && imagesFilter.length && imagesFilter[0].photographer" class="mt-3 caption text-center">Click image to view gallery</p>
<!--        <p v-if="priorityImageSrcHover && priorityImageCaption" class="mt-3 body-1 text-center">{{ priorityImageCaption }}</p>-->
<!--        <p v-if="!priorityImageSrcHover && imagesFilter.length && imagesFilter[0].caption && imagesFilter[0].caption!=='NULL'" class="mt-3 body-1 text-center">{{ imagesFilter[0].caption }}</p>-->
        <LightGallery
          v-if="galleryVisible" :gallery-items="galleryItems" @close-gallery="hideGallery">
        </LightGallery>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" sm="3" offset="1" class="mt-6">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text">Counties</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table dense style="max-height: 400px; overflow-y: auto">
          <template v-slot:default>
            <tbody>
            <tr v-for="item in locationData"
              :key="item">
              <td class="text-justify">{{ item }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="10" offset="1" sm="7" offset-sm="0" class="mt-6" style="min-height: 450px">
        <iframe width="100%" height="100%" :src="mapBaseURL" :key="flora_data[0]?.plant_id"></iframe>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line no-unused-vars
import LightGallery from '@/components/LightGallery.vue';

export default {
  name: 'FloraDetails',
  components: {
    LightGallery,
  },
  props: {
    floraData: Array,
    imageData: Array,
    imagesFilter: Array,
    textData: String,
    mapBaseURL: String,
    locationData: Array,
    admin: Boolean,
    priorityImageSrc: String,
    priorityImageSrcHover: String,
    priorityImagePhotographer: String,
    priorityImageCaption: String,
  },
  data() {
    return {
      search: '',
      dynamic: 'dynamic',
      headers: [
        { text: 'County/Area', value: 'location' },
      ],
      transition: 'scale-transition',
      placeholderUrl: '/static/img/comingsoon_hover.png',
      isMobile: false,
      galleryVisible: false,
      flora_data: [],
    };
  },
  computed: {
    multipleEntries() {
      return this.flora_data.loaded && this.flora_data.length > 1;
    },
    // Add computed functions for native & adventive varieties
    varietyN() {
      if(this.multipleEntries){
        for(let d of this.flora_data){
          if(d.na==="N"){
            return d;
          }
        }
      }
    },
    varietyA() {
      if(this.multipleEntries){
        for(let d of this.flora_data){
          if(d.na==="A"){
            return d;
          }
        }
      }
    }
  },
  methods: {
    showGallery() {
      this.galleryVisible = false;
      this.galleryItems = {};
      this.galleryVisible = true;
      this.galleryItems = this.imagesFilter;
      this.galleryItems.map(e => e.scientificName = this.flora_data[0].scientific_name)
    },
    hideGallery() {
      this.galleryItems = {};
      this.galleryVisible = false;
    },
    forceRerender() {
      //console.log('event passed back');
    },
  },
  watch: {
    // This keeps local data in sync
    floraData(updateObj) {
      this.flora_data = updateObj;
      this.flora_data.loaded=true;
    },
  },
};
</script>

<style scoped>
  .lightbox {
    /** Default lightbox to hidden */
    display: none;

    /** Position and style */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
  }

  .lightbox img {
    /** Pad the lightbox image */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .lightbox:target {
    /** Remove default browser outline */
    outline: none;

    /** Show lightbox **/
    display: block;
  }
  .v-application p {
    margin-bottom: 12px !important;
  }
  .v-data-footer ::v-deep {
    justify-content: flex-start !important;

  }
  [v-cloak] {
    display: none;
  }

</style>
